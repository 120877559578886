import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { API_PATH } from './config.js';

class Retorno extends Component {
    constructor (props) {
        super(props)
        this.state = {
          loading: true,
          currentPage: 1,
          casos: [],
          searchTerm: '',
        }
    }

    componentDidMount() {
        this.fetchRetorno();
    }
    

    fetchRetorno = () => {
      axios.get(API_PATH + "nueva_normalidad?page=1&per_page=100")
        .then(response => {
          const casos = response.data;
          this.setState({ casos, loading: false }, () => {
            // Realiza la búsqueda inicial cuando se cargan los casos
            this.handleSearchChange({ target: { value: '' } });
          });
        })
        .catch(error => {
          console.error('Error fetching casos:', error);
          this.setState({ loading: false });
        });
    }

    handleSearchChange = (event) => {
        this.setState({ searchTerm: event.target.value });
    }

    getPaginatedRetorno = () => {
      const { currentPage, casos, searchTerm } = this.state;
      const pageSize = 5; // Tamaño de página deseado
      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      // Filtra los casos basados en el término de búsqueda
       // Convierte el término de búsqueda a minúsculas para realizar una comparación insensible a la capitalización
      const lowercaseSearchTerm = searchTerm.toLowerCase();
      const filteredRetorno = casos.filter((item) =>
        (item.content.rendered.toLowerCase().includes(lowercaseSearchTerm) || item.title.rendered.toLowerCase().includes(lowercaseSearchTerm))
  );

  return filteredRetorno.slice(startIndex, endIndex);
    }

    goToPreviousPage = () => {
      const { currentPage } = this.state;
      if (currentPage > 1) {
        this.setState({ currentPage: currentPage - 1 });
      }
    }
    
    goToNextPage = () => {
      const { currentPage, casos } = this.state;
      const pageSize = 5; // Tamaño de página deseado
      const totalPages = Math.ceil(casos.length / pageSize);
      if (currentPage < totalPages) {
        this.setState({ currentPage: currentPage + 1 });
      }
    }

    Sanitize(title) {
      var rawMarkup = title;
      return { __html: rawMarkup };
    }

    render() {
      const { loading, searchTerm, currentPage } = this.state;
      const totalPages = Math.ceil(this.state.casos.length / 5); // Calcular el número total de páginas
      if (loading) {
        <div className="preloader" >
          <div className="div-transform">
            <div className="loader">
              <div className="face">
                <div className="circle"></div>
                  </div>
                   <div className="face">
                 <div className="circle"></div>
              </div>
            </div>
          </div>
        </div>
      }
      const casos = this.getPaginatedRetorno();

      return (
          <>
            <div className="container-fluid bg-black" >
              <br/><br/>
              <h1 align="center" className="tituloPrincipal"><b>B2N</b></h1>
            </div>
            <br/><br/>
            <div className='container'>
              <div className='row dropDownContainer'>
                <div className="col-md-6" >

                </div>
                  <div className="col-md-6 serch-news" >
                    <input
                          type="text"
                          value={searchTerm}
                          onChange={this.handleSearchChange}
                          placeholder="Buscar"
                          className="search-field"
                    />
                    <i className="fa fa-search white"></i>
                    <hr className="hrb63" />
                  </div>
                </div>
              <br/><br/>
              {casos.map((casos, index) => {
                // var d = new Date(casos.date);
                // var fechan = new Date(d);
                // var options = { month: 'long', day: 'numeric',  year: 'numeric' };
                // var fechr= fechan.toLocaleDateString("es-MX", options)
                var contenido = casos.content.rendered;
                var regex = /(<([^>]+)>)/ig
            ,   body = contenido
            ,   result = body.replace(regex, "");
                let imagen = casos.acf.imagen
                let title = casos.title.rendered

                return (
                    <div key={index}>
                      <div className="row">
                        <div className="col-md-6 " >
                          <img class="img-card2" src={imagen} alt="img-card"/>
                        </div>
                        <div className="col-md-6" >
                            <h3 className="message-news-2" dangerouslySetInnerHTML={this.Sanitize(title)}></h3>
                         
                           <hr className="hrb4" /><br />  
                            <p className="pjus-n">{result.substr(0, 150)}</p>
                            <Link
                                to={{
                                    pathname: `b2n/${casos.id}`,
                                    state: {casos }
                                }}
                                className="leia-mas"
                            >
                            Ver más 
                            </Link>
                        </div>
                      </div>
                      <br/>
                      <hr className="hr-sweet" />
                      <br/>
                    </div>
                  );
                })
              }
              <div className='row'>
                <div className='col-md-12 center'>
                  {currentPage > 1 && (
                    <button onClick={this.goToPreviousPage} className='btn-primary btn-news'>Anterior</button> 
                  )}
                  &nbsp;&nbsp;
                 {currentPage < totalPages && (
                    <button onClick={this.goToNextPage} className='btn-primary btn-news'>Siguiente</button>
                  )}
                </div>
              </div>
            </div><br/><br/><br/>
          </>
      );
    }
  }

export default(Retorno);