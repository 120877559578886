import React, { Component } from 'react';
import axios from 'axios';
import Libro from './images/BOOK_ICON.png';
import { API_PATH } from './config.js';

class SalaDeLectura extends Component {
    constructor (props) {
        super(props)
        this.state = {
          loading: true,
          currentPage: 1,
          news: [],
          searchTerm: '',
        }
    }

    componentDidMount() {
        this.fetchNews();
    }
    

    fetchNews = () => {
      axios.get(API_PATH + "sala_lectura?page=1&per_page=100")
        .then(response => {
          const news = response.data;
          this.setState({ news, loading: false }, () => {
            // Realiza la búsqueda inicial cuando se cargan las noticias
            this.handleSearchChange({ target: { value: '' } });
          });
        })
        .catch(error => {
          console.error('Error fetching news:', error);
          this.setState({ loading: false });
        });
    }

    handleSearchChange = (event) => {
        this.setState({ searchTerm: event.target.value });
    }

    getPaginatedNews = () => {
      const { currentPage, news, searchTerm } = this.state;
      const pageSize = 10; // Tamaño de página deseado
      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      // Filtra las noticias basadas en el término de búsqueda
       // Convierte el término de búsqueda a minúsculas para realizar una comparación insensible a la capitalización
      const lowercaseSearchTerm = searchTerm.toLowerCase();
      const filteredNews = news.filter((item) =>
        (item.title.rendered.toLowerCase().includes(lowercaseSearchTerm))
  );

  return filteredNews.slice(startIndex, endIndex);
    }

    goToPreviousPage = () => {
      const { currentPage } = this.state;
      if (currentPage > 1) {
        this.setState({ currentPage: currentPage - 1 });
      }
    }
    
    goToNextPage = () => {
      const { currentPage, news } = this.state;
      const pageSize = 10; // Tamaño de página deseado
      const totalPages = Math.ceil(news.length / pageSize);
      if (currentPage < totalPages) {
        this.setState({ currentPage: currentPage + 1 });
      }
    }

    Sanitize(title) {
      var rawMarkup = title;
      return { __html: rawMarkup };
    }

    render() {
      const { loading, searchTerm, currentPage } = this.state;
      const totalPages = Math.ceil(this.state.news.length / 10); // Calcular el número total de páginas
      if (loading) {
        <div className="preloader" >
          <div className="div-transform">
            <div className="loader">
              <div className="face">
                <div className="circle"></div>
                  </div>
                   <div className="face">
                 <div className="circle"></div>
              </div>
            </div>
          </div>
        </div>
      }
      const news = this.getPaginatedNews();

      return (
          <>
            <div className="container-fluid bg-black" >
              <br/><br/>
              <h1 align="center" className="tituloPrincipal"><b>SALA <span className="title-blue">DE</span> LECTURA</b></h1>
            </div>
            <br/><br/>
            <div className='container'>
                <div className='row dropDownContainer'>
                    <div className="col-md-6" >
                        Consulta los libros que tenemos disponibles en nuestra biblioteca
                    </div>
                    <div className="col-md-6 serch-news" >
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={this.handleSearchChange}
                            placeholder="Buscar"
                            className="search-field"
                        />
                        <i className="fa fa-search white"></i>
                        <hr className="hrb63" />
                    </div>
                </div>
                <br/><br/>
                <div className='row'>
                {news.map((noticias, index) => {
                return (
                    
                        <div className="col-md-3 " key ={index}>
                            <div className="alto-libros">
                                <img  src={Libro} alt="libro" />
                                <div>
                                    <span className="span-white">{noticias.acf.autor_editorial}</span>
                                    <br /><hr className="hrb4" /><br />
                                    <h3 className="message-news3" dangerouslySetInnerHTML={this.Sanitize(noticias.title.rendered)}></h3>
                                </div>
                            </div>
                        </div>
                    
                  );
                })
              }</div>
              <div className='row'>
                <div className='col-md-12 center'>
                  {currentPage > 1 && (
                    <button onClick={this.goToPreviousPage} className='btn-primary btn-news'>Anterior</button> 
                  )}
                   &nbsp;&nbsp;
                 {currentPage < totalPages && (
                    <button onClick={this.goToNextPage} className='btn-primary btn-news'>Siguiente</button>
                  )}
                </div>
              </div>
            </div>
            <br/><br/><br/>
          </>
      );
    }
  }

export default(SalaDeLectura);