import React, { Component } from 'react';
import axios from 'axios';
import { API_PATH} from './config.js';

class Aniversarios extends Component {
    constructor (props) {
        super(props)
        this.state = {
          loading: true,
          data:[],
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.fetchaniversario();
        }, 100);
    }

    fetchaniversario = () => {
        axios.get(API_PATH + "aniversario_laboral?page=1&per_page=100")
        .then(response => {
            const data = response.data;
            this.setState({ data, loading: false });
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    }

    Sanitize(title) {
        var rawMarkup = title;
        return { __html: rawMarkup };
    }
    render() {
        const { loading, data } = this.state;
        if (loading === true) {
            return(
                <div className="preloader" >
                    <div className="div-transform">
                        <div className="loader">
                            <div className="face">
                                <div className="circle"></div>
                            </div>
                            <div className="face">
                                <div className="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <>
                <div className="container-fluid bg-black" >
                <br/><br/>
                <h1 align="center" className="tituloPrincipal"><b><span className="title-blue">ANIVERSARIOS</span></b></h1>
                </div>
                <br/><br/>
                <div className='container'>
                    <div className='row'>
                        {data.map((aniversario, index) => {
                            var months = new Array(12);
                            months[0] = "enero";
                            months[1] = "febrero";
                            months[2] = "marzo";
                            months[3] = "abril";
                            months[4] = "mayo";
                            months[5] = "junio";
                            months[6] = "julio";
                            months[7] = "agosto";
                            months[8] = "septiembre";
                            months[9] = "octubre";
                            months[10] = "noviembre";
                            months[11] = "diciembre";



                            function traducirMes(mes) {
                                const mesesTraducidos = {
                                    "enero": "January",
                                    "febrero": "February",
                                    "marzo": "March",
                                    "abril": "April",
                                    "mayo": "May",
                                    "junio": "June",
                                    "julio": "July",
                                    "agosto": "August",
                                    "septiembre": "September",
                                    "octubre": "October",
                                    "noviembre": "November",
                                    "diciembre": "December"
                                };
                                return mesesTraducidos[mes.toLowerCase()];
                            }
                
                            let nombre = aniversario.title.rendered;
                            let fecha = aniversario.acf.fecha_ingreso;
                            let foto = aniversario.acf.foto;
                            
                            var partesFecha = fecha.split(" "); // Divide la cadena en partes (mes, día, año)
                            var mesTraducido = traducirMes(partesFecha[0]); // Traduce el nombre del mes
                            var fechaFinal = mesTraducido + " " + partesFecha[1] + ", " + partesFecha[2];

                            var d = new Date(fechaFinal);
                        console.log(d)
                            var n = d.getMonth();
                            var anio = d.getFullYear();
                            var anio_actual = new Date().getFullYear();
                            var anios= anio_actual - anio;
                            var s 
                
                            if (anios > 1){
                                s = 'años'
                            }
                            else {
                                s = 'año'
                            }
                
                            return (
                                <div className="col-md-4 carousel-innersmall img__wrap" key ={index}>
                                    <div className="tile-slide-container">
                                        <img className="img-fluid" alt={nombre} src={foto} />
                                    </div>
                                    <div className="img__description">
                                        <div className="title">
                                            <h3 dangerouslySetInnerHTML={this.Sanitize(nombre)}></h3>
                                            <hr className="hrwhite" />
                                            <h5>{months[n]},{anio}</h5>
                                            <h5>{anios} {s}</h5>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            );
                        })}
                    </div>
                </div>
                <br/><br/>
            </>
        )
    }
}
export default Aniversarios;