import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import logo from './logo_wm_blue.png'

/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const SignInButton = () => {
    const { instance } = useMsal();

    const handleLogin = (loginType) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch(e => {
                console.log(e);
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            });
        }
    }
    return (
        <div className="padre-transform">
            <div>
            <img src="https://mccannet.mccann.com.mx/image/logo_mccann.png" alt="logo"/>
            <br/>
            <span style={{color:'#fff', fontSize:'14x'}}>Para poder acceder a la Mccannet da clic en Iniciar sesión</span>
            <br/> <br/>
            <button onClick={() => handleLogin("popup")} className="boton-login">Iniciar Sesión</button>
            </div>
      </div>
    )
}