import React, { Component } from 'react';
import axios from 'axios';
import { API_PATH} from './config.js';

class LosQueCumplen extends Component {
    constructor (props) {
        super(props)
        this.state = {
          loading: true,
          data:[],
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.fetchcumplen();
        }, 100);
    }

    fetchcumplen = () => {
        axios.get(API_PATH + "los_que_cumplen?page=1&per_page=100")
        .then(response => {
            const data = response.data;
            this.setState({ data, loading: false });
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    }

    Sanitize(title) {
        var rawMarkup = title;
        return { __html: rawMarkup };
    }
    render() {
        const { loading, data } = this.state;
        if (loading === true) {
            return(
                <div className="preloader" >
                    <div className="div-transform">
                        <div className="loader">
                            <div className="face">
                                <div className="circle"></div>
                            </div>
                            <div className="face">
                                <div className="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <>
                <div className="container-fluid bg-black" >
                <br/><br/>
                <h1 align="center" className="tituloPrincipal"><b>LOS <span className="title-blue">QUE</span> cumplen</b></h1>
                </div>
                <br/><br/>
                <div className='container'>
                    <div className='row'>
                        {data.map((cumplen, index) => {
                            var months = new Array(12);
                            months[0] = "Enero";
                            months[1] = "Febrero";
                            months[2] = "Marzo";
                            months[3] = "Abril";
                            months[4] = "Mayo";
                            months[5] = "Junio";
                            months[6] = "Julio";
                            months[7] = "Agosto";
                            months[8] = "Septiembre";
                            months[9] = "Octubre";
                            months[10] = "Noviembre";
                            months[11] = "Diciembre";
                
                            let nombre = cumplen.title.rendered;
                            let fecha = cumplen.acf.fecha;
                            let foto = cumplen.acf.foto;
                            var fechan = new Date(fecha);
                            var options = { month: 'long', day: 'numeric' };
                            var fechr= fechan.toLocaleDateString("es-ES", options)
                
                            return (
                                <div className="col-md-4 carousel-innersmall img__wrap" key ={index}>
                                    <div className="tile-slide-container">
                                        <img className="img-fluid" alt={nombre} src={foto} />
                                    </div>
                                    <div className="img__description">
                                        <div className="title">
                                            <h3 dangerouslySetInnerHTML={this.Sanitize(nombre)}></h3>
                                            <hr className="hrwhite" />
                                            <h5>{fechr}</h5>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            );
                        })}
                    </div>
                </div>
                <br/><br/>
            </>
        )
    }
}
export default LosQueCumplen;