import React, { Component } from 'react';
import axios from 'axios';
import { API_PATH} from './config.js';

class LosQueLlegan extends Component {
    constructor (props) {
        super(props)
        this.state = {
          loading: true,
          data:[],
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.fetchLlegan();
        }, 100);
    }

    fetchLlegan = () => {
        axios.get(API_PATH + "los_que_llegan?page=1&per_page=100")
        .then(response => {
            const data = response.data;
            this.setState({ data, loading: false });
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    }

    Sanitize(title) {
        var rawMarkup = title;
        return { __html: rawMarkup };
    }
    render() {
        const { loading, data } = this.state;
        if (loading === true) {
            return(
                <div className="preloader" >
                    <div className="div-transform">
                        <div className="loader">
                            <div className="face">
                                <div className="circle"></div>
                            </div>
                            <div className="face">
                                <div className="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <>
                <div className="container-fluid bg-black" >
                <br/><br/>
                <h1 align="center" className="tituloPrincipal"><b>LOS <span className="title-blue">QUE</span> LLEGAN</b></h1>
                </div>
                <br/><br/>
                <div className='container'>
                    <div className='row'>
                        {data.map((llegan, index) => {
                            let nombre = llegan.title.rendered;
                            let foto = llegan.acf.foto;
                            let puesto = llegan.acf.new_person_divition;
                            var contenidoParse;
                            var contenido = llegan.content.rendered;
                            var res = contenido.replace(new RegExp("<p>", 'g'), "");
                            res = res.replace(new RegExp("</p>", 'g'), "");
                            res = res.replace(new RegExp("<ul>", 'g'), "</br></br>");
                            res = res.replace(new RegExp("<ul id='no'>", 'g'), "");
                            res = res.replace(new RegExp("</ul>", 'g'), "");
                            res = res.replace(new RegExp("</li>", 'g'), "</br>");
                            res = res.replace(new RegExp("<li>", 'g'), "<i class='fa fa-angle-right'></i>&nbsp;");
                            contenidoParse = res.replace(new RegExp("<a", 'g'), "<a class='aBlack'");
                
                            return (
                                <div className="col-md-4 ingresos img_llegan flip-card" key ={index}>
                                    <div className="flip-card-inner">
                                        <div className="flip-card-front">
                                            <img src={foto} alt={nombre} className="img-fluid" />
                                        </div>
                                        <div className="flip-card-back">
                                            <div className="sinopsis_description">
                                                <div className="title-flip">
                                                    <h5>{puesto}</h5>
                                                        <hr className="hrwhite" />
                                                        <h3 className="nombre-llegan" dangerouslySetInnerHTML={this.Sanitize(nombre)}></h3>
                                                        <div className="sinopsis" dangerouslySetInnerHTML={this.Sanitize(contenidoParse)}></div>
                                                </div>                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <br/><br/>
            </>
        )
    }
}
export default LosQueLlegan;