import React, { Component } from 'react';
import axios from 'axios';
import { API_PATH} from './config.js';
import Slider from "react-slick";
import Logohome from './images/LOGO_MCCANN.png';
import Nosotros from './images/BOTON_NOSOTROS.png';
import WorkApps from './images/BOTON_WORKAPPS.png';
import Enterate from './images/BOTON_ENTERATE.png';
import Politicas from './images/BOTON_POLITICAS-FORMATOS.png';
import Red from './images/BOTON_RED_MCCANN.png';

class Home extends Component {
    constructor (props) {
        super(props)
        this.state = {
          data:[],
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.fetchNoticias();
        }, 100);
    }

    fetchNoticias = () => {
        axios.get(API_PATH + "avisos")
        .then(response => {
            const data = response.data;
            this.setState({ data });
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    }

    Sanitize(title) {
        var rawMarkup = title;
        return { __html: rawMarkup };
    }

    getNoticias = () => {
        const {data } = this.state;
       
        const noticias = data.map((noticias, index) => {
        let url = noticias.acf.seccion_aviso;
        let modal = noticias.acf.pop_up;
        console.log(noticias)
            if( modal === false || modal === undefined){
                return (
                    <div className="contenedor" key={index}>
                        <a href={url} target='_blank' rel="noopener noreferrer">
                        <div className="avisos">
                            <div className="av">  
                                <img src={noticias.better_featured_image.source_url} alt="slide"/> 
                            </div>  
                        </div>
                        </a>
                    </div>
                )
            }else{
                return (
                    <div className="contenedor" key={index}>
                        <div className="avisos">
                            <div className="av">  
                                <img src={noticias.better_featured_image.source_url} alt="slide"/> 
                            </div>  
                        </div>  
                    </div>
                )
            }
        });
        return noticias;
    }

    render() {
        const settings_3 = {
            dots: true,
            fade: false,
            infinite: true,
            speed: 7000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,     
        };
        return (
            <div>    
                <div className="bg-home" >
                    <div className="logo-mccann-home">
                        <span className="transparent" >
                            <img src={Logohome} className="logo-mw-home" alt="logo"/>
                        </span>
                    </div> 
                            
                    <div className="container-fluid bg-slider">
                        <div className="container">
                            <br/><br/>
                            <Slider {...settings_3} className=' margin-slider'>
                               
                                <div className="avisos" data-bs-toggle="modal" data-bs-target="#video0">
                                    <div className="av" >  
                                        <img className="d-block w-100" src="https://mccannet.mccann.com.mx/wp-content/uploads/2024/07/McCANN-Proudgroup-2.png" alt="slide"/> 
                                    </div>  
                                </div> 
                                <div className="avisos" data-bs-toggle="modal" data-bs-target="#videoTalento">
                                    <div className="av" >  
                                        <img className="d-block w-100" src="https://mccannet.mccann.com.mx/wp-content/uploads/2023/10/ComunicadoTalento-MX.png" alt="slide"/> 
                                    </div>  
                                </div>
                                {this.getNoticias()}
                                
                                <div className="avisos" data-bs-toggle="modal" data-bs-target="#video1">
                                    <div className="av" >  
                                        <img className="d-block w-100" src="https://mccannet.mccann.com.mx/wp-content/uploads/2020/04/COLABORADORES_V2.png" alt="slide"/> 
                                    </div>  
                                </div> 
                                <div className="avisos" data-bs-toggle="modal" data-bs-target="#video2">
                                    <div className="av" >  
                                        <img className="d-block w-100" src="https://mccannet.mccann.com.mx/wp-content/uploads/2020/04/APROBADORES_V2.png" alt="slide"/> 
                                    </div>  
                                </div>
                            </Slider>      
                        </div>  
                        <br/><br/><br/> 
                    </div>

                    <div className="container-fluid bg-iconos">
                        <br/> <br/>
                        <div className='photo-grid-container'>
                            <div className='photo-grid'>
                                <div className='photo-grid-item first-item' data-bs-toggle="modal" data-bs-target="#ModalRed">   
                                    <img className="buttons-img-home" src={Red} alt="buttons-img-home"/>
                                    <span className="title-buttons">Red<br/> Mccann</span>
                                </div>
                                <div className='photo-grid-item' data-bs-toggle="modal" data-bs-target="#ModalEnterate">
                                    <img className="buttons-img-home" src={Enterate} alt="buttons-img-home"/>
                                    <span className="title-buttons">Entérate</span>
                                </div>
                                <a href="/workapps" className='decoration-none'>
                                    <div className='photo-grid-item'>
                                        <img className="buttons-img-home" src={WorkApps} alt="buttons-img-home"/>
                                        <span className="title-buttons">Work Apps</span>
                                    </div>
                                </a>
                                <div className='photo-grid-item' data-bs-toggle="modal" data-bs-target="#ModalPoliticas">
                                    <img className="buttons-img-home" src={Politicas} alt="buttons-img-home"/>
                                    <span className="title-buttons">Políticas y <br/>formatos</span>
                                </div>
                                <div className='photo-grid-item last-item' data-bs-toggle="modal" data-bs-target="#ModalNosotros">
                                    <img className="buttons-img-home" src={Nosotros} alt="buttons-img-home"/>
                                    <span className="title-buttons">Nosotros</span>
                                </div>
                            </div>
                            </div><br/> <br/> 
                        </div>
                    </div>


                    {/* NOSOTROS */}
                    <div className="modal bd-example-modal-sm" id="ModalNosotros" tabIndex="-1" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-sm" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">Nosotros</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                <div>               
                                    <ul>
                                    <li> <a className="a-black" href='https://mccannet.mccann.com.mx/organigrama'>Organigrama</a></li>
                                    <li> <a className="a-black" href='https://mccannet.mccann.com.mx/losquellegan'>Los que llegan</a></li>
                                    <li> <a className="a-black" href='https://mccannet.mccann.com.mx/losquecrecen'>Los que crecen</a></li>
                                    <li> <a className="a-black" href='https://mccannet.mccann.com.mx/losquecumplen'>Los que cumplen</a></li>
                                    <li> <a className="a-black" href='https://mccannet.mccann.com.mx/algoparacelebrar'>Algo para celebrar</a></li>
                                    <li> <a className="a-black" href='https://mccannet.mccann.com.mx/aniversarios'>Aniversarios</a></li>
                                    <li> <a className="a-black" href='https://mccannet.mccann.com.mx/conoce'>Conoce mejor a</a></li> 
                                    </ul>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div> 

                    {/* ENTÉRATE */}
                    <div className="modal bd-example-modal-sm" id="ModalEnterate" tabIndex="-1"  aria-labelledby="mySmallModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-sm" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">Entérate</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                <div>               
                                <ul>
                                    <li><a className="a-black" href='https://mccannet.mccann.com.mx/b2n'>B2N</a></li>
                                    <li><a className="a-black" href='https://mccannet.mccann.com.mx/noticias'>Noticias</a></li>
                                    <li><a className="a-black" href='https://mccannet.mccann.com.mx/cultura-bienestar'>Cultura y Bienestar</a></li>
                                    <li><a className="a-black" href='https://mccannet.mccann.com.mx/vacantes'>Vacantes</a></li>
                                    <li><a className="a-black" href='https://mccannet.mccann.com.mx/tu-salud'>Tu Salud</a></li>
                                    <li><a className="a-black" href='https://mccannet.mccann.com.mx/otras-cosas-buenas'>Otras cosas buenas</a></li>
                                    <li><a className="a-black" href='https://mccannet.mccann.com.mx/sala-de-lectura'>Sala de lectura</a></li>
                                    <li><a className="a-black" href='https://mccannet.mccann.com.mx/el-corcho'>El Corcho</a></li> 
                                </ul>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div> 

                    {/* POLÍTICAS Y FORMATOS */}
                    <div className="modal bd-example-modal-sm" id="ModalPoliticas" tabIndex="-1" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-sm" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">Políticas y formatos locales</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                <div>               
                                    <ul>
                                    <li><a href="https://inside.interpublic.com/login/" className="a-black" target="_blank" rel="noopener noreferrer">Políticas Globales</a></li>
                                    <li><a className="a-black" href='https://mccannet.mccann.com.mx/politicas-y-formatos'>Políticas y formatos locales</a></li> 
                                    <li><a className="a-black" href='https://mccannet.mccann.com.mx/finanzas'>Finanzas</a></li> 
                                    <li><a href="https://tmt.mccann.com.mx/" className="a-black" target="_blank" rel="noopener noreferrer">Requisición de Talento</a></li>
                                    <li><a href="https://inside.interpublic.com/missionandvalues/alertline" className="a-black" target="_blank" rel="noopener noreferrer">Alert Line</a></li> 
                                    </ul>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div> 

                    {/* RED MCCANN */}
                    <div className="modal bd-example-modal-sm" id="ModalRed" tabIndex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-sm" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">Red McCann</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                <div>               
                                    <ul>
                                        <li> <a href="https://intranet-latam.mccann.com/" className="a-black" target="_blank" rel="noopener noreferrer">INSIDE LATAM</a></li>
                                        <li> <a href="https://inside.mccannwg.com/" className="a-black" target="_blank" rel="noopener noreferrer">INSIDE WG</a></li>
                                        <li> <a href="https://inside.interpublic.com/" className="a-black" target="_blank" rel="noopener noreferrer">INSIDE INTERPUBLIC</a></li>
                                    </ul>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div> 


                    <div className="modal fade" id="video0" tabIndex="-1"  aria-labelledby="video0" aria-hidden="true">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">McCann Proud Group</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body" style={{overflow:'hidden'}}>
                                    <div align="center">
                                        <video controls id="vid3"  >
                                            <source src="https://mccannet.mccann.com.mx/wp-content/uploads/2024/07/Campana-Inclusion_McCan_1920x1080_v16.mp4" type="video/mp4" />
                                        </video> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 

                    <div className="modal fade" id="videoTalento" tabIndex="-1"  aria-labelledby="videoTalento" aria-hidden="true">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">CONOCE AL EQUIPO DE TALENTO</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body" style={{overflow:'hidden'}}>
                                    <div align="center">
                                        <video controls id="vid3"  >
                                            <source src="https://mccannet.mccann.com.mx/wp-content/uploads/2023/10/Comunicado_Talento_Video_v2.mp4" type="video/mp4" />
                                        </video> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 


                    <div className="modal fade" id="video1" tabIndex="-1"  aria-labelledby="video1" aria-hidden="true">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">KIOSCO DE INCIDENCIAS COLABORADORES</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body" style={{overflow:'hidden'}}>
                                    <div align="center">
                                        <video controls id="vid3"  >
                                            <source src="https://mccannet.mccann.com.mx/wp-content/uploads/2020/04/RH-KIOSCO-COLABORADORES.mp4" type="video/mp4" />
                                        </video> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 

                    <div className="modal fade" id="video2" tabIndex="-1"  aria-labelledby="video2" aria-hidden="true">
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">KIOSCO DE INCIDENCIAS APROBADORES</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body" style={{overflow:'hidden'}}>
                                    <div align="center">
                                        <video controls id="vid4"  >
                                            <source src="https://mccannet.mccann.com.mx/wp-content/uploads/2020/04/RH-KIOSCO-aprobadores.mp4" type="video/mp4" />
                                        </video> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  



                </div>   
        )
    }
}
export default Home;