import React, { Component } from 'react';
import axios from 'axios';
import { API_PATH} from './config.js';

class Organigrama extends Component {
    constructor (props) {
        super(props)
        this.state = {
          loading: true,
          data:[],
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.fetchDirectorio();
        }, 100);
    }

    fetchDirectorio = () => {
        axios.get(API_PATH + "caras?page=1&per_page=100")
        .then(response => {
            const data = response.data;
            this.setState({ data, loading: false });
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    }

    Sanitize(title) {
        var rawMarkup = title;
        return { __html: rawMarkup };
    }
    render() {
        const { loading, data } = this.state;
        var mig1 = 'Organigrama';
        if (loading === true) {
            return(
                <div className="preloader" >
                    <div className="div-transform">
                        <div className="loader">
                            <div className="face">
                                <div className="circle"></div>
                            </div>
                            <div className="face">
                                <div className="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <>
                <div className="container-fluid bg-black" >
                <br/><br/> <br/><br/>
                    <h1 align="center" className="tituloPrincipal"><b><span className="title-blue">{mig1}</span></b></h1>
                </div>
                <br/><br/>
                <div className='container'>
                    
                    <div className='row'>
                        {data.map((directorio, index) => {
                            let nombre = directorio.title.rendered;
                            let puesto = directorio.acf.employee_position;
                            let foto = directorio.better_featured_image.source_url;
                            return (
                                <div className="col-md-4 col-lg-4 carousel-innersmall img__wrap" key ={index}>
                                    <div className="tile-slide-container">
                                        <img className="img-fluid" alt={puesto} src={foto} />
                                    </div>
                                    <div className="img__description">
                                        <div className="title">
                                            <h3 dangerouslySetInnerHTML={this.Sanitize(nombre)}></h3>
                                            <hr className="hrwhite" />
                                            <h6>{puesto}</h6>
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            );
                        })}
                    </div>
                </div>
                <br/><br/>
            </>
        )
    }
}
export default Organigrama;