import React, { Component } from 'react';

class Error404 extends Component {
    render() {

        return (
            <div className="texto-centro espacing container">
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <h1 className="text-big">404</h1>
                <h2 className="error404">THE PAGE ARE YOU LOOKING IS NOT FOUND.
                    <br />
                </h2>
                <p className="error404-p">
                    Go to the <a href="/">main page</a>
                </p>
            </div>
        )

    }
}

export default (Error404);
