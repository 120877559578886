import React from "react";
import {  withRouter } from "react-router-dom";

class Search extends React.Component {
    state = {
      searchText: ""
    };
  

    
    handleRoute = route => () => {
      this.props.history.push({ pathname: route });
    };
  
    handleSearchInput = event => {
      this.setState({
        searchText: event.target.value
      });
    };
  
    handleSearchSubmit = () => {
      
      if (this.state.searchText) {
        
        this.props.history.push({
          pathname: "/resultados",
          state: {
            searchText: this.state.searchText
          }
          
          
        });
        
      } else {
        alert("Digite algum texto de pesquisa");
      }
    };
  

    handleKeyPress = event => {
      if (event.key === 'Enter') {
        this.handleSearchSubmit();
      }
    };

    render() {
      return (
        <div>
         <div className="container">
                <div className="row">
                    
                    <div className="col-sm-12 col-md-12 col-lg-12" >
                      <div className="input-buscador">
                    <i onClick={this.handleSearchSubmit}  className="fa fa-search" style={{color:'#fff'}}></i><input type="text" className="search-field" id="searchDirectory"  onKeyPress={ this.handleKeyPress}  onChange={this.handleSearchInput} value={this.state.searchText}  autoComplete="off" placeholder="Buscar" />
                   
                    </div>
                    </div>
                </div> 
            </div>  

        </div>
      );
    }
  }
export default withRouter(Search);