import React, { Component } from 'react';
import axios from 'axios';
import { API_PATH} from './config.js';
import Slider from "react-slick";
import IcoInfo from './images/ico_info.png';
import IcoContact from './images/ico_contact.png';
import IcoPhone from './images/ico_phone.png';
import IcoWeb from './images/ico_web.png';


class ElCorcho extends Component {
    constructor (props) {
        super(props)
        this.state = {
          loading: true,
          data:[],
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.fetchCorcho();
        }, 100);
    }

    fetchCorcho = () => {
        axios.get(API_PATH + "el_corcho?page=1&per_page=100")
        .then(response => {
            const data = response.data;
            this.setState({ data, loading: false });
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    }

    Sanitize(title) {
        var rawMarkup = title;
        return { __html: rawMarkup };
    }
    render() {
        const { loading, data } = this.state;
        if (loading === true) {
            return(
                <div className="preloader" >
                    <div className="div-transform">
                        <div className="loader">
                            <div className="face">
                                <div className="circle"></div>
                            </div>
                            <div className="face">
                                <div className="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <>
                <div className="container-fluid bg-black" >
                    <br/><br/><br/><br/>
                    <h1 align="center" className="tituloPrincipal"><b>EL<span className="title-blue"> CORCHO</span></b></h1>
                </div>
                <br/><br/>
                <div className='container'>
                    <div className="row">   
                        <span className="pjus">
                        Porque tú lo pediste, regresa el tablero de anuncios dedicado a la socialización entre McCanners. Aquí podrás ofrecer o encontrar diferentes cosas y servicios como roomies, compañeros de trayecto, adoptar mascotas y mucho más. Para publicar tu anuncio debes contactar a RH.
                        </span>
                    </div>
                    <br/><br/>
                    <div className='row'>
                        {data.map((Corcho, index) => {
                            function imgModal(){
                                var array = Corcho.acf.imagenes_corcho;
                                    for(var i in array) {
                                    var imagenes_modal = "";
                                        for ( i = 0; i < array.length; i++) {
                                            imagenes_modal += "<div class='bg-corcho'><img src='"+ array[i].imagenes +" 'class='image-responsive img-corcho'/>"+ "</div>";
                                        }
                                    return imagenes_modal
                                }
                            }
                
                            var contenidoParse;
                            var contenido = Corcho.content.rendered;
                            var res = contenido.replace(new RegExp("<p>", 'g'), "");
                            res = res.replace(new RegExp("</p>", 'g'), "");
                            res = res.replace(new RegExp("<ul>", 'g'), "</br></br>");
                            res = res.replace(new RegExp("</ul>", 'g'), "");
                            res = res.replace(new RegExp("</li>", 'g'), "</br>");
                            // res = res.replace(new RegExp("<li>", 'g'), "<i class='fa fa-angle-right'></i>&nbsp;");
                            contenidoParse = res.replace(new RegExp("<a", 'g'), "<a class='aBlue'");
                            var settings_3 = {
                                dots: true,
                                fade: false,
                                infinite: true,
                                speed: 2000,
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                autoplay: false,    
                              };
                
                            var siteweb = Corcho.acf.sitio;
                            var telefono= Corcho.acf.telefono;
                            if(siteweb == "" || siteweb == undefined ){
                                    var sitio = "";
                                    var icositio ="";
                            }
                            else{
                                  sitio = "<a href='"+Corcho.acf.sitio+"' target='_blank'>"+Corcho.acf.sitio+"</a>";
                                  icositio="<img src='"+IcoWeb+"' alt='icon-site'/>";
                            }
                
                            if(telefono == "" || telefono == undefined){
                                var tel = "";
                                var icontel="";
                            }
                            else{
                              tel = Corcho.acf.telefono;
                              icontel="<img src='"+IcoPhone+"' alt='icon-phone'/>";
                            }

                            return (
                                <div className="container-fluid" key={index}>
                                    <div className="row">
                                        <div className="col-md-4" >
                                            <Slider {...settings_3}>
                                            <div dangerouslySetInnerHTML={this.Sanitize(imgModal())}></div>
                                            {/* {ReactHtmlParser(imgModal())} */}
                                            </Slider>                   
                                        </div>
                                        <div className="col-md-8 " >
                                            <h3 className="message-news" dangerouslySetInnerHTML={this.Sanitize(Corcho.title.rendered)}></h3>
                                            <hr className="hrb4" />
                                            <br />
                                            <div className="derecha">
                                                <img src={IcoInfo} />
                                            </div>
                                            <div className="info-corcho">
                                                <div dangerouslySetInnerHTML={this.Sanitize(contenidoParse)}></div>
                                            </div>
                                            <br/><br/>


                                            <div className="derecha">
                                                <img src={IcoContact} />    
                                            </div>
                                            <div className="info-corcho">
                                                <div dangerouslySetInnerHTML={this.Sanitize(Corcho.acf.contacto)}></div>
                                            </div>
                                            <br/><br/>

                                            <div className="derecha">
                                                <div dangerouslySetInnerHTML={this.Sanitize(icontel)}></div>
                                            </div>
                                            <div className="info-corcho">
                                                <div dangerouslySetInnerHTML={this.Sanitize(tel)}></div>
                                            </div>
                                            <br/><br/>

                                            <div className="derecha">
                                                <div dangerouslySetInnerHTML={this.Sanitize(icositio)}></div>
                                            </div>
                                            <div className="info-corcho">
                                                <div dangerouslySetInnerHTML={this.Sanitize(sitio)}></div>
                                            </div>
                                            <br/><br/>
                                            
                                        </div>
                                    </div>
                                    <hr className="hr-sweet" />
                                </div>
                            );
                        })}
                    </div>
                </div>
                <br/><br/>
            </>
        )
    }
}
export default ElCorcho;