/**
 * CONFIGURACION DEL MENU
*/

import React, {Component} from 'react';
import Logo from './Initial/images/logo_mccannet.png'
import Search from './Buscador'

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userFeed: '',
      loading: true,
      activeLink: null,
    }
  }

  componentDidMount() {
    const path = window.location.pathname;
    this.setState({ activeLink: path });
  }

  render() {
    

    return (
        <div className='container-fluid bg-menu'>
          <div className='container'>
            <img src={Logo} alt="logo" className=''/>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark z-index">
              <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">

                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item li-menu-1">
                      <a className="nav-link" aria-current="page" href="/">Home</a>
                    </li>
                    
                    <li className="nav-item li-menu-1 dropdown">
                      <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                       Nosotros
                      </a>
                      <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><a className="dropdown-item" href="/organigrama">Organigrama</a></li>
                        <li><a className="dropdown-item" href="/losquellegan">Los que Llegan</a></li>
                        <li><a className="dropdown-item" href="/losquecrecen">Los que Crecen</a></li>
                        <li><a className="dropdown-item" href="/losquecumplen">Los que cumplen</a></li>
                        <li><a className="dropdown-item" href="/algoparacelebrar">Algo para celebrar</a></li>
                        <li><a className="dropdown-item" href="/aniversarios">Aniversarios</a></li>
                        <li><a className="dropdown-item" href="/conocemejora">Conoce mejor a</a></li>
                      </ul>
                    </li>

                    <li className="nav-item li-menu-1 dropdown">
                      <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                       Entérate
                      </a>
                      <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><a className="dropdown-item" href="/b2n">B2N</a></li>
                        <li><a className="dropdown-item" href="/noticias">Noticias</a></li>
                        <li><a className="dropdown-item" href="/cultura-bienestar">Cultura y Bienestar</a></li>
                        <li><a className="dropdown-item" href="/vacantes">Vacantes</a></li>
                        <li><a className="dropdown-item" href="/tu-salud">Tu Salud</a></li>
                        <li><a className="dropdown-item" href="/otras-cosas-buenas">Otras Cosas Buenas</a></li>
                        <li><a className="dropdown-item" href="/sala-de-lectura">Sala de Lectura</a></li>
                        <li><a className="dropdown-item" href="/el-corcho">El Corcho</a></li>
                      </ul>
                    </li>

                    <li className="nav-item li-menu-1">
                      <a className="nav-link" aria-current="page" href="/workapps">Work Apps</a>
                    </li>

                    <li className="nav-item li-menu dropdown">
                      <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Políticas y Formatos
                      </a>
                      <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><a className="dropdown-item" href="https://inside.interpublic.com/login/" target="_blank" rel="noopener noreferrer">Políticas Globales</a></li>
                        <li><a className="dropdown-item" href="/politicas-y-formatos">Políticas y formatos locales</a></li>
                        <li><a className="dropdown-item" href="/finanzas">Finanzas</a></li>
                        <li><a className="dropdown-item" href="https://tmt.mccann.com.mx/" target="_blank" rel="noopener noreferrer">Requisición de talento</a></li>
                        <li><a className="dropdown-item" href="https://inside.interpublic.com/missionandvalues/alertline" target="_blank" rel="noopener noreferrer">Alert Line</a></li>
                      </ul>
                    </li>

                    <li className="nav-item li-menu dropdown">
                      <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                       Red McCann
                      </a>
                      <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><a className="dropdown-item" href="https://interpublic.sharepoint.com/sites/MWG-InsideLatam_SPA" target="_blank" rel="noopener noreferrer">INSIDE LATAM</a></li>
                        <li><a className="dropdown-item" href="https://interpublic.sharepoint.com/sites/MWG-InsideGlobal/" target="_blank" rel="noopener noreferrer">INSIDE WG</a></li>
                        <li><a className="dropdown-item" href="https://inside.interpublic.com/" target="_blank" rel="noopener noreferrer">INSIDE INTERPUBLIC</a></li>
                      </ul>
                    </li>

                  </ul>
                </div>
              </div>
            </nav>
            <div className="margin-search">
            <Search/>
            </div>
          </div>
          <aside id="sticky-social">
            <div className="redes-ss">
              <div className="padd-redes"><a href="https://fiori.interpublic.com/" className="entypo-timeshet" target="_blank" rel="noopener noreferrer"><span>Timesheet</span></a></div> 
              {/* <div className="padd-redes"><a href="https://mi-mercadito.mccann.com.mx/" className="entypo-mercadito" target="_blank"><span>Mi Mercadito McCann</span></a></div> */}
              <div className="padd-redes"><a href=" http://azrlmwgapp001:92/" className="entypo-kiosko" target="_blank" rel="noopener noreferrer"><span>Kiosco de Incidencias</span></a></div> 
              <div className="padd-redes"><a href="https://together.interpublic.com/" className="entypo-together" target="_blank" rel="noopener noreferrer"><span>Together</span></a></div> 
              <div className="padd-redes"><a href="https://interpublic.sharepoint.com/sites/EntrenamientosMcCann" rel="noopener noreferrer" className="entypo-entrenamientos" target="_blank"><span>IT/Entrenamientos/Soporte</span></a></div>   
              <div className="padd-redes"><a href="mailto:RHMcCann@mccann.com.mx" className="entypo-contactohome"></a></div>
              <div className="padd-redes cursor-pointer"><a data-bs-toggle="modal" data-bs-target="#ModalLong" className="entypo-calendariohome"></a></div>
              <div className="padd-redes"><a href="https://www.facebook.com/MccannWorldgroupMX" className="facebook" target="_blank" rel="noopener noreferrer"></a></div>
              <div className="padd-redes"><a href="https://twitter.com/mccannmexico" className="twitter" target="_blank" rel="noopener noreferrer"></a></div>
              <div className="padd-redes"><a href="https://www.instagram.com/mccannmexico/" className="instagram" target="_blank" rel="noopener noreferrer"></a></div>
            </div>
          </aside>

          {/* MODAL CALENDARIO*/}
          <div className="modal fade bd-example-modal-lg" id="ModalLong" tabIndex="-1"  aria-labelledby="exampleModalLongTitle" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">Calendario 2024</h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <div align="center">
                    <img src="https://mccannet.mccann.com.mx/wp-content/uploads/2024/07/Calenadario-Fechas-RH-2024.png" className="image-responsive" />
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    );
    }
}

export default NavBar;