import React, { Component } from 'react';
import axios from 'axios';
import { API_PATH} from './config.js';

class AlgoParaCelebrar extends Component {
    constructor (props) {
        super(props)
        this.state = {
          loading: true,
          data:[],
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.fetchFest();
        }, 100);
    }

    fetchFest = () => {
        axios.get(API_PATH + "Festividades?order=asc")
        .then(response => {
            const data = response.data;
            this.setState({ data, loading: false });
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    }

    Sanitize(title) {
        var rawMarkup = title;
        return { __html: rawMarkup };
    }
    render() {
        const { loading, data } = this.state;
        if (loading === true) {
            return(
                <div className="preloader" >
                    <div className="div-transform">
                        <div className="loader">
                            <div className="face">
                                <div className="circle"></div>
                            </div>
                            <div className="face">
                                <div className="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <>
                <div className="container-fluid bg-black" >
                <br/><br/>
                <h1 align="center" className="tituloPrincipal"><b>ALGO <span className="title-blue">PARA</span> CELEBRAR</b></h1>
                </div>
                <br/><br/>
                <div className='container'>
                    <div className='row'>
                        {data.map((Fest, index) => {
                            var contenidoParse;
                            var contenido = Fest.content.rendered;
                            var res = contenido.replace(new RegExp("<p>", 'g'), "");
                            res = res.replace(new RegExp("</p>", 'g'), "");
                            res = res.replace(new RegExp("<ul>", 'g'), "</br></br>");
                            res = res.replace(new RegExp("</ul>", 'g'), "");
                            res = res.replace(new RegExp("</li>", 'g'), "</br>");
                            // res = res.replace(new RegExp("<li>", 'g'), "<i class='fa fa-angle-right'></i>&nbsp;");
                            contenidoParse = res.replace(new RegExp("<a", 'g'), "<a class='aBlue'");
                            let titulo = Fest.title.rendered;
                            let imagen = Fest.better_featured_image.source_url;
                            return (
                                <div key={index}>
                                    <div className="row">
                                        <div className="col-md-6" >
                                            <img className="img-card2" alt="img" src={imagen}/>
                                        </div>
                                        <div className="col-md-6" >
                                            <h3 className="message-news" dangerouslySetInnerHTML={this.Sanitize(titulo)}></h3>
                                            <hr className="hrb4" />
                                            <br />
                                            <span className="pjus" dangerouslySetInnerHTML={this.Sanitize(contenidoParse)}></span> 
                                        </div>
                                    </div>
                                    <hr className="hr-sweet" />
                                    <br/>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <br/><br/>
            </>
        )
    }
}
export default AlgoParaCelebrar;