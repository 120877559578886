import React, { Component } from 'react';

class NoticiaId extends Component {
    componentDidMount() {
        // console.log(this.props.location)
    }

    Sanitize(title) {
        var rawMarkup = title;
        return { __html: rawMarkup };
    }

    setDataNew = () => {
        const title = this.props.location.state.casos.title.rendered;
        // const fechaNota =this.props.location.state.casos.date;
        // const imagen = this.props.location.state.casos.acf.imagen;
        // var d = new Date(fechaNota);
        // var fechan = new Date(d);
        // var options = { month: 'long', day: 'numeric',  year: 'numeric' };
        // var fechr= fechan.toLocaleDateString("es-MX", options) 
        const video = this.props.location.state.casos.acf.archivo;
        const contenido = this.props.location.state.casos.content.rendered;
        var videoLink 

        if(video === ""){
            videoLink=""
        }else{
            videoLink= <embed src={video} width='100%' height='600px'/>
        }

        return (
            <div className='row'>
                <h1 className="content-news-h1" dangerouslySetInnerHTML={this.Sanitize(title)}></h1>
                {/* <span className="fecha-n">{fechr}</span> */}
                <br /><br />
                <div>
                <br />
                <span className="pjus"  dangerouslySetInnerHTML={this.Sanitize(contenido)}></span>
                <br/><br/>
                {videoLink}
                </div>
            </div>
        )  
    }

    render() {
        return (
            <>
                <div className="container-fluid bg-black" >
                    <br/><br/>
                    <h1 align="center" className="tituloPrincipal"><b>Noticias</b></h1>
                </div>
                <br/><br/>
                <div className="container">
                    {this.setDataNew()}
                    <br/> <br/>
                </div>
            </>
        )
    }
}

export default NoticiaId;