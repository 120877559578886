import React, { Component } from 'react';
import axios from 'axios';
import { API_PATH} from './config.js';

class Bienestar extends Component {
    constructor (props) {
        super(props)
        this.state = {
          loading: true,
          data:[],
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.fetchBienestar();
        }, 100);
    }

    fetchBienestar = () => {
        axios.get(API_PATH + "bienestar?page=1&per_page=100")
        .then(response => {
            const data = response.data;
            this.setState({ data, loading: false });
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    }

    Sanitize(title) {
        var rawMarkup = title;
        return { __html: rawMarkup };
    }
    render() {
        const { loading, data } = this.state;
        if (loading === true) {
            return(
                <div className="preloader" >
                    <div className="div-transform">
                        <div className="loader">
                            <div className="face">
                                <div className="circle"></div>
                            </div>
                            <div className="face">
                                <div className="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <>
                <div className="container-fluid bg-black" >
                    <br/><br/>
                    <h1 align="center" className="tituloPrincipal"><b>CULTURA<span className="title-blue"> Y </span>BIENESTAR </b></h1>
                </div>
                <br/><br/>
                <div className='container'>
                    <div className='row'>
                        {data.map((Bienestar, index) => {
                            let post_id = Bienestar.slug;
                            let boton = Bienestar.acf.ver_mas;
                            let boton2 = Bienestar.acf.ver_ar;
                            let boton3 = Bienestar.acf.ver_mas_video;
                            var archivo;
                            var link;
                
                            if (boton === true){
                               archivo="<img src='"+ Bienestar.acf.imagenes +" 'class='image-responsive'/>";
                               link="<div class='btn-videos-bienestar'data-bs-toggle='modal' data-bs-target='#"+post_id+"'>Ver más</div>"
                            }
                            else if(boton2 === true){
                                archivo ="<embed src='"+ Bienestar.acf.archivo +"' width='100%' height='600px'/>"
                                link="<div class='btn-videos-bienestar'data-bs-toggle='modal' data-bs-target='#"+post_id+"'>Ver más</div>"
                            }
                            else if(boton3 === true){
                                link="<a href='"+Bienestar.acf.url_video+"' target='_blank' class='decoration-none'><div class='btn-videos-bienestar'>Ver video</div></a>"
                            }
                
                            var contenidoParse;
                            var contenido = Bienestar.content.rendered;
                            var res = contenido.replace(new RegExp("<p>", 'g'), "");
                            res = res.replace(new RegExp("</p>", 'g'), "");
                            res = res.replace(new RegExp("<ul>", 'g'), "</br></br>");
                            res = res.replace(new RegExp("<ul id='no'>", 'g'), "");
                            res = res.replace(new RegExp("</ul>", 'g'), "");
                            res = res.replace(new RegExp("</li>", 'g'), "</br>");
                            res = res.replace(new RegExp("<li>", 'g'), "<i class='fa fa-angle-right'></i>&nbsp;");
                            contenidoParse = res.replace(new RegExp("<a", 'g'), "<a class='aBlack'");
                            let foto = Bienestar.acf.imagen
                            return (
                                <div className="col-md-4 cards-innersmall img__wrap-cards" key ={index}>
                                    <div className="tile-slide-container">
                                        <img className="img-fluid" src={foto} />
                                    </div>
                                    <div className="img__description">
                                        <div className="title">
                                            <h3 dangerouslySetInnerHTML={this.Sanitize(contenidoParse)}></h3><br/>
                                            <span dangerouslySetInnerHTML={this.Sanitize(link)}></span><br/>
                                            {/* <hr className="hrwhite" />
                                            <h6>{puesto}</h6> */}
                                        </div>
                                    </div>
                                    <br />

                                    <div className="modal fade" id={post_id} tabIndex="-1"  aria-labelledby={post_id} aria-hidden="true">
                                        <div className="modal-dialog modal-lg" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h1 className="modal-title fs-5" id="exampleModalLabel" dangerouslySetInnerHTML={this.Sanitize(Bienestar.title.rendered)}></h1>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">
                                                    <div align="center">
                                                    <span dangerouslySetInnerHTML={this.Sanitize(archivo)}></span><br/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            );
                        })}
                    </div>
                </div>
                <br/><br/>
            </>
        )
    }
}
export default Bienestar;