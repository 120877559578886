import React, { Component } from 'react';
import axios from 'axios';
import { API_PATH} from './config.js';

class Vacantes extends Component {
    constructor (props) {
        super(props)
        this.state = {
          loading: true,
          data:[],
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.fetchVacantes();
        }, 100);
    }

    fetchVacantes = () => {
        axios.get(API_PATH + "se_busca?page=1&per_page=100")
        .then(response => {
            const data = response.data;
            this.setState({ data, loading: false });
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    }

    Sanitize(title) {
        var rawMarkup = title;
        return { __html: rawMarkup };
    }
    render() {
        const { loading, data } = this.state;
        if (loading === true) {
            return(
                <div className="preloader" >
                    <div className="div-transform">
                        <div className="loader">
                            <div className="face">
                                <div className="circle"></div>
                            </div>
                            <div className="face">
                                <div className="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <>
                <div className="container-fluid bg-black" >
                    <br/><br/><br/><br/>
                    <h1 align="center" className="tituloPrincipal"><b>Vacantes</b></h1>
                </div>
                <br/><br/>
                <div className='container'>
                    <div className="row">   
                        <span className="pjus">
                            Si llegó tu momento de crecer o cambiar de rumbo, no es necesario abandonar el barco...<br/>   
                            Te queremos con nosotros, queremos que alcances tus metas, Te queremos feliz,Te queremos a ti.<br/>   
                            Por eso te ofrecemos, antes que, a nadie, la oportunidad de postularte (o postular a alguien que conozcas) para estas posiciones.<br/><br/> <br/>   
                        </span>
                    </div>
                    <div className='row'>
                        {data.map((Vacantes, index) => {
                            var contenidoParse;
                            var contenido = Vacantes.content.rendered;
                            var res = contenido.replace(new RegExp("<p>", 'g'), "");
                            res = res.replace(new RegExp("</p>", 'g'), "");
                            res = res.replace(new RegExp("<ul>", 'g'), "</br></br>");
                            res = res.replace(new RegExp("</ul>", 'g'), "");
                            res = res.replace(new RegExp("</li>", 'g'), "</br>");
                            res = res.replace(new RegExp("<li>", 'g'), "<i className='fa fa-angle-right'></i>&nbsp;");
                            contenidoParse = res.replace(new RegExp("<a", 'g'), "<a className='aBlue'");
                            let titulo = Vacantes.title.rendered;

                            return (
                                <div  data-toggle="modal" data-bs-toggle="modal" data-bs-target="#exampleModal" key={index}>
                                    <div className="row cursor-pointer" >
                                        <div className="col-md-6 texto-centro" >
                                            <img  alt="img" src={Vacantes.acf.logo}/>
                                        </div>
                                        <div className="col-md-6" >
                                            <h3 className="message-news" dangerouslySetInnerHTML={this.Sanitize(titulo)}></h3>
                                            <hr className="hrb4" />
                                            <br />
                                            <span className="pjus" dangerouslySetInnerHTML={this.Sanitize(contenidoParse)}></span> 
                                        </div>
                                    </div>

                                    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h1 className="modal-title fs-5" id="exampleModalLabel">Proceso de Reclutamiento</h1>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                            <div className="modal-body">
                                                <h4>a) Elegibilidad</h4>
                                                <span>Los colaboradores pueden postularse a cualquier posición abierta si:</span>
                                                    <ol>
                                                        <li>Cubren con el perfil requerido por Recursos Humanos</li>
                                                        <li>Cuentan con cuando menos 6 meses en la posición actual</li>
                                                        <li>No tengan actas administrativas en su expediente laboral</li>
                                                        <li>Contar con la recomendación de su jefe directo</li>
                                                    </ol>
                                                <h4>b) Proceso de selección</h4>
                                                    <span>Cuando un colaborador esté interesado en postularse o en revisar las condiciones de una vacante será necesario que siga los siguientes pasas en este orden:</span>
                                                    <ol>
                                                        <li>El solicitante deberá comentar con su jefe directo el interés en participar en el proceso de selección</li>
                                                        <li>Será necesario que el aspirante acuda a Recursos Humanos para revisar el perfil de la posición abierta para ver si desde su punto de vista cubre con las necesidades del puesto.</li>
                                                        <li>Recursos Humanos realizara una entrevista profunda para conocer los intereses del candidato y sus aspiraciones económicas para realizar el análisis de compensación además de asegurar el perfil</li>
                                                        <li>Si el candidato es viable se turnara a la segunda ronda de entrevistas ya con el área solicitante.</li>
                                                        <li>De ser caso realizara las evaluaciones técnicas correspondientes.</li>
                                                        <li>Terminadas las entrevistas tendremos el cierre del proceso en donde se dará la retroalimentación y en su caso la oferta económica.</li>
                                                        <li>Una vez que la oferta es aceptada Recursos Humanos pactara a través del formato de movimiento interno anexo A con las áreas involucradas la fecha de inicio del candidato en su nuevo puesto</li>
                                                </ol>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-black" data-dismiss="modal">Cerrar</button>
                                                    {/* <a href="mailto:johnny.mendez@mccann.com?subject=Se%20busca"><button type="button" className="btn btn-black">Postularse</button></a> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>       
                                    <hr className="hr-sweet" />
                                    <br/>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <br/><br/>
            </>
        )
    }
}
export default Vacantes;