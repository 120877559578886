import React, { Component } from 'react';
import axios from 'axios';
import { API_PATH} from './config.js';

class OtrasCosasBuenas extends Component {
    constructor (props) {
        super(props)
        this.state = {
          loading: true,
          data:[],
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.fetchCosas();
        }, 100);
    }

    fetchCosas = () => {
        axios.get(API_PATH + "cosas_buenas?page=1&per_page=100")
        .then(response => {
            const data = response.data;
            this.setState({ data, loading: false });
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    }

    Sanitize(title) {
        var rawMarkup = title;
        return { __html: rawMarkup };
    }
    render() {
        const { loading, data } = this.state;
        if (loading === true) {
            return(
                <div className="preloader" >
                    <div className="div-transform">
                        <div className="loader">
                            <div className="face">
                                <div className="circle"></div>
                            </div>
                            <div className="face">
                                <div className="circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <>
                <div className="container-fluid bg-black" >
                    <br/><br/>
                    <h1 align="center" className="tituloPrincipal"><b>OTRAS <span className="title-blue">COSAS</span> BUENAS</b></h1>
                </div>
                <br/><br/>
                <div className='container'>
                    <div className='row'>
                        {data.map((Cosas, index) => {
                            let post_id = Cosas.slug;
                            let boton = Cosas.acf.ver_mas;
                            let boton2 = Cosas.acf.ver_ar;
                            var btn
                            var verMas
                            var archivo;
                
                            if (boton === true){
                                btn = "btn-videos-bienestar";
                                verMas = "Ver Más";
                               archivo="<img src='"+ Cosas.acf.imagenes +" 'class='image-responsive'/>";
                                //console.log(imagen);
                            }
                            else if(boton2 === true){
                                btn = "btn-videos-bienestar";
                                verMas = "Ver Más";
                                archivo ="<embed src='"+ Cosas.acf.archivo +"' width='100%' height='600px'/>"
                            }

                            let foto = Cosas.better_featured_image.source_url
                            var contenidoParse;
                            var contenido = Cosas.content.rendered;
                            var res = contenido.replace(new RegExp("<p>", 'g'), "");
                            res = res.replace(new RegExp("</p>", 'g'), "");
                            res = res.replace(new RegExp("<ul>", 'g'), "</br></br>");
                            res = res.replace(new RegExp("<ul id='no'>", 'g'), "");
                            res = res.replace(new RegExp("</ul>", 'g'), "");
                            res = res.replace(new RegExp("</li>", 'g'), "</br>");
                            res = res.replace(new RegExp("<li>", 'g'), "<i class='fa fa-angle-right'></i>&nbsp;");
                            contenidoParse = res.replace(new RegExp("<a", 'g'), "<a class='aBlack'");

                            return (
                                <div className="col-md-4 cards-innersmall img__wrap-cards" key ={index}>
                                    <div className="tile-slide-container">
                                        <img className="img-fluid" src={foto} />
                                    </div>
                                    <div className="img__description">
                                        <div className="title-cosas">
                                            <div dangerouslySetInnerHTML={this.Sanitize(contenidoParse)}></div><br/>
                                            <div className={btn}  data-bs-toggle="modal"  data-bs-target={"#"+post_id}>{verMas}</div>
                                        </div>
                                    </div>
                                    <br/>

                                    <div className="modal fade" id={post_id} tabIndex="-1"  aria-labelledby={post_id} aria-hidden="true">
                                        <div className="modal-dialog modal-lg" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h1 className="modal-title fs-5" id="exampleModalLabel" dangerouslySetInnerHTML={this.Sanitize(Cosas.title.rendered)}></h1>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">
                                                    <div align="center">
                                                    <span dangerouslySetInnerHTML={this.Sanitize(archivo)}></span><br/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                            </div>
                            );
                        })}
                    </div>
                </div>
                <br/><br/>
            </>
        )
    }
}
export default OtrasCosasBuenas;